import {Injectable} from '@angular/core';
import {filter, Observable, Subject} from "rxjs";
import {Alert, AlertOptions, AlertType} from "../models/alert";
import {HttpErrorResponse} from "@angular/common/http";
import {BookSave} from "../models/bookSave";
import {BillingPreferences} from "../models/billingPreferences";
import moment from "moment";
import {ConfigurationService} from "./configuration.service";

@Injectable({
  providedIn: 'root'
})
export class BookingSaverService {
  private localStorageKey = "bookingSave";
  private appointmentSlotBookTime = 60000;

  constructor(
    private configurationService: ConfigurationService,
    ) {
    this.configurationService.get("TEMPS_BLOCAGE_RDV").subscribe({
      next: data => {
        this.appointmentSlotBookTime = data.datas.value*60000
      }
    })
  }

  init(context: string, step: number): BookSave
  {
    localStorage.setItem(this.localStorageKey, JSON.stringify(new BookSave(context, step)));
    return JSON.parse(localStorage.getItem(this.localStorageKey));
  }

  isPendingBooking() : boolean
  {
    let item = this.getSaved();
    if(item)
    {
      if(moment().diff(moment(item.saveStartDate), "minutes") < 15)
      {
        return true;
      }
      else
      {
        this.clear();
        return false;
      }
    }
    return false;
  }

  isBookingValid() : boolean
  {
    if(this.getSaved() && !this.getSaved().timerDate) return true;

    let millis = moment().diff(moment(this.getSaved()?.timerDate, true).local(true));
    millis = this.appointmentSlotBookTime - millis;
    return millis > 0;
  }

  getSaved(): BookSave
  {
    let item = localStorage.getItem(this.localStorageKey);
    if(!item) return null;
    return JSON.parse(item);
  }

  clear()
  {
    localStorage.removeItem(this.localStorageKey);
  }

  setContext(context: string)
  {
    let bookSave = this.getSaved();
    if(bookSave) {
      bookSave.context = context;
      this.save(bookSave);
    }
  }

  setStep(step: number)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.step = step;
      this.save(bookSave);
    }
  }

  setMode(mode: number)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.mode = mode;
      this.save(bookSave);
    }
  }

  setSelectedVoyant(selectedVoyantId: number)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.selectedVoyantId = selectedVoyantId;
      this.save(bookSave);
    }
  }

  setSelectedPhone(selectedPhone: string)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.selectedPhone = selectedPhone;
      this.save(bookSave);
    }
  }

  setBillingPreferences(billingPreferences: BillingPreferences)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.billingPreferences = billingPreferences;
      this.save(bookSave);
    }
  }

  setCreditCardId(creditCardId: number)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.creditCardId = creditCardId;
      this.save(bookSave);
    }
  }

  setSelectedSlot(selectedSlot: any)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.selectedSlot = selectedSlot;
      this.save(bookSave);
    }
  }

  setTimerDate(timerDate: any)
  {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.timerDate = timerDate;
      this.save(bookSave);
    }
  }

  setHigherStep(higherStep: number) {
    let bookSave = this.getSaved();
    if(bookSave)
    {
      bookSave.higherStep = higherStep;
      this.save(bookSave);
    }
  }

  private save(bookSave: BookSave)
  {
    localStorage.setItem(this.localStorageKey, JSON.stringify(bookSave));
  }
}
