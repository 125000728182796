import { Injectable } from '@angular/core';
import {RestApiService} from "@makeo-packages/mkongtools";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public apiEndpoint: string = 'configuration';

  constructor(protected apiService: RestApiService) {
  }

  public get(key: string): Observable<any> {
    return this.apiService.get(`${this.apiEndpoint}`, key);
  }
}
