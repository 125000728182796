import {BillingPreferences} from "./billingPreferences";
import moment from "moment";

export class BookSave {
  saveStartDate: string;
  context: string;
  step: number;

  mode?: number;
  selectedVoyantId?: number;
  selectedPhone?: string;
  billingPreferences?: BillingPreferences;
  creditCardId?: number;
  selectedSlot?: any;

  timerDate: any;
  higherStep: number;


  constructor(context: string, step: number) {
    this.context = context;
    this.step = step;
    this.saveStartDate = moment().toISOString()
  }
}
